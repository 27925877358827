#overlay {
  position: fixed; /* Sit on top of the page content */
  /* display: none; Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
}




.timer {
  display: flex;
  font-size: 30px;
  justify-content: center;
}
.timer > div {
  padding: 10px;
  background: white;
  color: black;
  font-size: 40px;
  margin-right: 2px;
  width: 100px;
  display: flex;
  justify-content: center;
}
.timer > div > span {
  text-transform: uppercase;
  color: rgb(105, 105, 105);
  font-size: 20px;
  display: flex;
  align-items: center;
}
.expired {
  font-size: 20px;
  color: rgb(126, 49, 49);
  border: 1px solid rgb(126, 49, 49);
  padding: 20px;
}








.emailcomp {
  padding: 30px;
  background-color: white;
  border: 2px solid dodgerblue;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  height: 400px;
}

.emailcomp input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

.emailcompi input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

.emailcompi button {
  appearance: button;
  background-color: dodgerblue;
  background-image: none;
  border: 1px solid #000;
  border-radius: 4px;
  box-shadow: #fff 4px 4px 0 0, #000 4px 4px 0 1px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: ITCAvantGardeStd-Bk, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 5px 10px 0;
  overflow: visible;
  padding: 12px 40px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 40%;
}



/* CSS */

.emailcompi button:focus {
  text-decoration: none;
}

.emailcompi button:hover {
  text-decoration: none;
}

.emailcompi button:active {
  box-shadow: rgba(0, 0, 0, 0.125) 0 3px 5px inset;
  outline: 0;
}

.emailcompi button:not([disabled]):active {
  box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .emailcomp button {
    padding: 12px 50px;
  }
}
